<template>
  <div id="catalogTabs">
    <div class="catalog-tabs">
      <div class="catalog-tabs__main">
        <tabs :active="currentStep" @change="updateStep($event)">
          <step
            v-for="(category, index) in productTabs"
            :key="index"
            :name="category.name"
          >
            <category-items
              :products="category.products"
              :is-cart-enabled="isCartEnabled"
              :show-stock-errors="showStockErrors"
            ></category-items>
          </step>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryItems from "./CategoryItems";
import { mapGetters, mapActions, mapState } from "vuex";
import tabs from "../atoms/tabs";

export default {
  components: {
    CategoryItems,
    tabs,
  },
  data() {
    return {
      currentStep: 0,
    };
  },
  props: {
    isCartEnabled: {
      type: Boolean,
      default: true,
    },
    showStockErrors: {
      type: Boolean,
      default: true,
    },
    productTabs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    updateStep(next) {
      this.currentStep = next;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@lib/styles/helpers/breakpoints";

#catalogTabs {
  box-sizing: border-box;
  padding: 0 0 3rem 0;
  @include for-desktop {
    padding: 0 var(--spacer-sm) 3rem var(--spacer-sm);
    max-width: 1272px;
    margin: 0 auto;
  }
}
.catalog-tabs {
  --steps-content-padding: 0 var(--spacer-sm);
  @include for-desktop {
    --steps-content-padding: 0;
    display: flex;
  }
  &__main {
    @include for-desktop {
      flex: 1;
      width: 100%;
    }
  }
  &__aside {
    @include for-desktop {
      flex: 0 0 26.8125rem;
      margin: 0 0 0 var(--spacer-xl);
    }
    &-order {
      box-sizing: border-box;
      width: 100%;
      box-shadow: 0px 4px 11px rgba(var(--c-dark-base), 0.1);
      background: var(--c-light);
      padding: var(--spacer-xl) calc(var(--spacer-lg) * 2);
    }
  }
}
</style>
