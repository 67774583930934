<template>
  <div class="catalog container p0">
    <SfBreadcrumbs
      class="breadcrumbs desktop-only"
      :breadcrumbs="[
        {
          route: {
            link: {
              name: 'home',
            },
          },
          text: 'home',
        },
        {
          route: {
            link: {
              name: 'catalog',
            },
          },
          text: 'catalog',
        },
      ]"
    >
      <template #link="{ breadcrumb }">
        <router-link
          :to="breadcrumb.route.link"
          class="sf-breadcrumbs__breadcrumb"
        >
          {{ breadcrumb.text }}
        </router-link>
      </template>
    </SfBreadcrumbs>
    <div class="catalog-tabs mobile-only">
      <div class="catalog-tabs__main">
        <div class="tabs">
          <div class="tabs__header">
            <button
              v-for="(category, index) in categoryList"
              :key="index"
              :class="[
                'tabs__step',
                selectedCategory === category.id ? 'tabs__step--current' : '',
              ]"
              @click="selectCategory(category.id)"
            >
              <span class="tabs__title">{{ category.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar section">
      <div class="navbar__aside desktop-only">
        <SfHeading :level="3" title="Categories" class="navbar__title" />
      </div>
      <div class="navbar__main">
        <div class="navbar__sort">
          <span class="navbar__label desktop-only">Sort by:</span>
          <SfSelect v-model="sortBy" class="navbar__select">
            <SfSelectOption
              v-for="option in sortByOptions"
              :key="option.value"
              :value="option.value"
              class="sort-by__option"
              >{{ option.label }}</SfSelectOption
            >
          </SfSelect>
        </div>
        <div class="navbar__counter desktop-only">
          <span class="navbar__label">Products found: </span>
          <span v-text="categoryProducts.length"></span>
        </div>
        <div class="navbar__view">
          <span class="navbar__view-label desktop-only">View</span>
          <SfIcon
            class="navbar__view-icon"
            :color="isGridView ? '#1D1F22' : '#BEBFC4'"
            icon="tiles"
            size="24px"
            role="button"
            aria-label="Change to grid view"
            :aria-pressed="isGridView"
            @click="setView('grid')"
          />
          <SfIcon
            class="navbar__view-icon"
            :color="!isGridView ? '#1D1F22' : '#BEBFC4'"
            icon="list"
            size="24px"
            role="button"
            aria-label="Change to list view"
            :aria-pressed="!isGridView"
            @click="setView('list')"
          />
        </div>
      </div>
    </div>
    <div class="main section" v-hammer:swipe.horizontal="onSwipe">
      <div class="sidebar desktop-only">
        <div class="category-list">
          <div
            v-for="category in enabledCategories"
            :key="category.id"
            :class="[
              'category-list-item',
              selectedCategory === category.id
                ? 'category-list-item--current'
                : '',
            ]"
            @click="selectCategory(category.id)"
          >
            <div class="category-list-item__name">
              <span>{{ category.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="products" v-if="!isGroupedView">
        <div
          v-if="isGridView"
          appear
          name="products__slide"
          tag="div"
          class="products__grid"
        >
          <ProductGridItem
            v-for="(product, i) in selectedProducts"
            :key="product.id"
            :style="{ '--index': i }"
            :product="product"
            :is-cart-enabled="isCartEnabled"
            :show-stock-errors="showStockErrors"
            :link="'/p/' + product.variantId"
            class="products__grid-item"
          />
        </div>
        <div
          v-else
          appear
          name="products__slide"
          tag="div"
          class="products__list"
        >
          <ProductListItem
            v-for="(product, i) in selectedProducts"
            :key="product.id"
            :style="{ '--index': i }"
            :product="product"
            :is-cart-enabled="isCartEnabled"
            :show-stock-errors="showStockErrors"
            :link="'/p/' + product.variantId"
            class="products__list-item"
          />
        </div>
      </div>
      <div class="products" v-else>
        <div
          v-if="isGridView"
          appear
          name="products__slide"
          tag="div"
          class="products__grid"
        >
          <CollectedProductGridItem
            v-for="(group, i) in productVariantGroups"
            :key="group[0].itemId"
            :style="{ '--index': i }"
            :products="group"
            :is-cart-enabled="isCartEnabled"
            :show-stock-errors="showStockErrors"
            class="products__grid-item"
          />
        </div>
        <div
          v-else
          appear
          name="products__slide"
          tag="div"
          class="products__list"
        >
          <CollectedProductListItem
            v-for="(group, i) in visibleProductVariantGroups"
            :key="group[0].itemId"
            :style="{ '--index': i }"
            :products="group"
            :is-cart-enabled="isCartEnabled"
            :show-stock-errors="showStockErrors"
            class="products__list-item"
          />
        </div>
      </div>
    </div>
    <div class="powered-by">
      <div style="display: inline-block; vertical-align: middle">
        Powered by
      </div>
      <a
        :href="getPoweredByLink()"
        style="
          display: inline-block;
          vertical-align: middle;
          height: 18px;
          width: 85px;
          margin-left: 5px;
        "
      >
        <img
          alt="Zobaze"
          src="https://pos-staging-0.web.app/assets/img/logo/zobaze%20logo.png"
          style="height: 100%; width: 100%"
        />
      </a>
    </div>
    <!-- <bottom-cart
      class="mobile-only"
      v-if="isCartEnabled && numberOfItems > 0"
      :products-count="numberOfItems"
      :total="cartTotal"
    /> -->
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import CategoryTabs from "@theme/components/Catalog/CategoryTabs.vue";
import BottomCart from "@theme/components/organisms/bottomCart";
import { mapGetters, mapActions, mapState } from "vuex";

import {
  ProductGridItem,
  ProductListItem,
  SfList,
  SfMenuItem,
  SfHeading,
  SfSelect,
  SfIcon,
  SfBreadcrumbs,
  CollectedProductGridItem,
  CollectedProductListItem,
} from "@lib/components";

import onBottomScroll from "@lib/mixins/onBottomScroll";

export default {
  components: {
    ProductGridItem,
    ProductListItem,
    SfHeading,
    SfSelect,
    SfIcon,
    SfBreadcrumbs,
    CollectedProductGridItem,
    CollectedProductListItem,
  },
  mixins: [onBottomScroll],
  data() {
    return {
      sortBy: "a-z",
      sortByOptions: [
        {
          value: "a-z",
          label: "Name",
        },
        {
          value: "latest",
          label: "Latest",
        },
        {
          value: "price-up",
          label: "Price from low to high",
        },
        {
          value: "price-down",
          label: "Price from high to low",
        },
      ],
      selectedCategory: "all",
      isGridView: true,
      isDefaultViewOverridden: false,
      productsDisplayCount: 20,
    };
  },
  watch: {
    selectedCategory() {
      this.productsDisplayCount = 20;
      this.$nextTick(() => {
        let tabs = document.querySelector(".tabs .tabs__header");
        if (!tabs) return;
        let selected = document.querySelector(
          ".tabs__header .tabs__step--current"
        );
        if (!selected) return;
        let scrollPosition = tabs.scrollLeft;
        let tabsWidth = tabs.getBoundingClientRect().width;
        let scrollRightPosition = tabsWidth + scrollPosition;
        let selectedRect = selected.getBoundingClientRect();

        let right = scrollPosition + selectedRect.right;
        let left = scrollPosition + selectedRect.left;
        if (right > scrollRightPosition) {
          tabs.scrollTo(right - tabsWidth, 0);
        } else if (left < scrollPosition) {
          tabs.scrollTo(left, 0);
        }
      });
    },
    "$route.query.ci": {
      handler(val) {
        this.selectedCategory = val || "all";
      },
    },
  },
  computed: {
    ...mapGetters({
      productList: "catalog/list",
      categoryList: "catalog/categories",
      productsInCart: "cart/getCartItems",
      numberOfItems: "cart/getNumberOfItems",
      store: "store",
      totals: "cart/getTotals",
      defaultMobileProductsView: "defaultMobileProductsView",
      defaultDesktopProductsView: "defaultDesktopProductsView",
    }),
    enabledProducts() {
      const enabledTags = this.$route.params.tags || [];
      const enabledCategories = this.$route.params.categories || [];
      let products = [];
      if (!enabledTags.length && !enabledCategories.length) {
        return this.productList;
      }
      if (enabledTags.length) {
        let enabledProducts = this.productList.filter((p) => {
          return enabledTags.some(
            (tag) => p.tags && p.tags.length && p.tags.includes(tag)
          );
        });
        products = products.concat(enabledProducts);
      }
      if (enabledCategories.length) {
        let enabledProducts = this.productList.filter((p) => {
          return enabledCategories.some((catId) => p.categoryId === catId);
        });
        products = products.concat(enabledProducts);
      }
      return products;
    },
    enabledCategories() {
      if (this.enabledProducts.length === this.productList.length) {
        return this.categoryList;
      }
      return this.categoryList.filter(
        (c) =>
          c.id === "all" ||
          this.enabledProducts.some((p) => p.categoryId === c.id)
      );
    },
    isCartEnabled() {
      return (
        this.store.orderConfig.orderEnabled ||
        this.store.orderConfig.enquiryEnabled
      );
    },
    selectedProducts: function () {
      let products = this.categoryProducts.concat([]);

      switch (this.sortBy) {
        case "a-z":
          products.sort((p1, p2) => {
            if (p1.title && p2.title) {
              return p1.title > p2.title ? 1 : -1;
            }
            return p1.id > p2.id ? 1 : -1;
          });
          break;
        case "latest":
          products.sort((p1, p2) => {
            if (p1.cAt && p2.cAt) {
              return p1.cAt._seconds < p2.cAt._seconds ? 1 : -1;
            }
            return p1.id < p2.id ? 1 : -1;
          });
          break;
        case "price-up":
          products.sort((p1, p2) => {
            return p1.basePrice - p2.basePrice;
          });
          break;
        case "price-down":
          products.sort((p1, p2) => {
            return p2.basePrice - p1.basePrice;
          });
          break;
      }
      return products.slice(0, this.productsDisplayCount);
    },
    categoryProducts() {
      let products =
        this.selectedCategory === "all"
          ? this.enabledProducts
          : this.enabledProducts.filter(
              (x) => x.categoryId === this.selectedCategory
            );
      return products;
    },
    isGroupedView() {
      return this.store.catalog && this.store.catalog.enableVariantGrouping;
    },
    productVariantGroups() {
      if (!this.isGroupedView) {
        return;
      }
      let groups = {};
      for (let product of this.categoryProducts) {
        groups[product.itemId] = groups[product.itemId] || [];
        groups[product.itemId].push(product);
      }
      let productGroups = Object.values(groups);
      productGroups.forEach((x) =>
        x.sort((p1, p2) => {
          if (p1.hasOwnProperty("position") && p2.hasOwnProperty("position")) {
            return p1.position > p2.position ? 1 : -1;
          } else {
            if (p1.variantName && p2.variantName) {
              return p1.variantName > p2.variantName ? 1 : -1;
            } else if (p1.variantName || p2.variantName) {
              return p1.variantName ? 1 : -1;
            } else return p1.price > p2.price ? 1 : -1;
          }
        })
      );
      return productGroups;
    },
    visibleProductVariantGroups() {
      const products = this.productVariantGroups.concat([]);

      switch (this.sortBy) {
        case "a-z":
          products.sort((i1, i2) => {
            const p1 = i1[0];
            const p2 = i2[0];
            if (p1.title && p2.title) {
              return p1.title > p2.title ? 1 : -1;
            }
            return p1.id > p2.id ? 1 : -1;
          });
          break;
        case "latest":
          products.sort((i1, i2) => {
            const p1 = i1[0];
            const p2 = i2[0];
            if (p1.cAt && p2.cAt) {
              return p1.cAt > p2.cAt ? 1 : -1;
            }
            return p1.id > p2.id ? 1 : -1;
          });
          break;
        case "price-up":
          products.sort((i1, i2) => {
            const p1 = i1[0];
            const p2 = i2[0];
            return p1.basePrice - p2.basePrice;
          });
          break;
        case "price-down":
          products.sort((i1, i2) => {
            const p1 = i1[0];
            const p2 = i2[0];
            return p2.basePrice - p1.basePrice;
          });
          break;
      }
      return products.slice(0, this.productsDisplayCount);
    },
    cartCount() {
      let count = 0;
      this.productsInCart.forEach((product) => {
        count = count + parseInt(product.qty);
      });
      return count;
    },
    cartTotal() {
      let total = this.totals.find((x) => x.code === "grand_total");
      return total.value;
    },
    showStockErrors() {
      return !this.store.orderConfig.allowOrderOnNoStock;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    this.productsDisplayCount = 20;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    if (this.$route.params.categoryId) {
      this.selectedCategory = this.$route.params.categoryId;
    }
    if (this.$route.query && this.$route.query.ci) {
      this.selectedCategory = this.$route.query.ci;
    }
  },
  methods: {
    selectCategory(id) {
      this.$router.push({
        name: "catalog",
        query: { ci: id },
      });
    },
    onSwipe(event) {
      let currentCategoryIndex = this.enabledCategories.findIndex(
        (x) => x.id === this.selectedCategory
      );
      let nextCategoryIndex =
        event.type === "swipeleft"
          ? currentCategoryIndex + 1
          : currentCategoryIndex - 1;
      if (nextCategoryIndex < 0) nextCategoryIndex = 0;
      if (nextCategoryIndex >= this.enabledCategories.length)
        nextCategoryIndex = this.enabledCategories.length - 1;
      //this.selectedCategory = this.enabledCategories[nextCategoryIndex].id;
      this.$router.push({
        name: "catalog",
        query: { ci: this.enabledCategories[nextCategoryIndex].id },
      });
    },
    onResize() {
      if (!this.isDefaultViewOverridden) {
        if (window.innerWidth >= 1024) {
          this.isGridView = this.defaultDesktopProductsView === "grid";
        } else {
          this.isGridView = this.defaultMobileProductsView === "grid";
        }
      }
    },
    setView(view) {
      this.isDefaultViewOverridden = true;
      this.isGridView = view === "grid";
    },
    async onBottomScroll() {
      this.productsDisplayCount =
        this.categoryProducts && this.categoryProducts.length
          ? Math.min(
              this.productsDisplayCount + 20,
              this.categoryProducts.length
            )
          : 20;
    },
    getPoweredByLink() {
      let link =
        "https://pos.zobaze.com/?utm_source=store-front&utm_medium=store-front&utm_campaign=store-front";
      if (window.screen && window.screen.width <= 720) {
        link =
          Math.random() > 0.5
            ? link
            : "https://play.google.com/store/apps/details?id=com.zobaze.pos&referrer=utm_source%3Dstore-front%26utm_medium%3Dstore-front%26utm_term%3Dstore-front%26utm_content%3Dstore-front%26utm_campaign%3Dstore-front";
      }
      return link;
    },
  },
};
</script>

<style lang="scss">
@import "~@lib/styles/helpers";

.catalog {
  ul {
    list-style-type: none;
    padding: 0;
  }

  .navbar {
    position: relative;
    display: flex;
    border: 1px solid var(--c-light-darken);
    border-width: 0 0 1px 0;
    @include for-desktop {
      border-width: 1px 0 1px 0;
    }
    &.section {
      padding: var(--spacer-xs) 16px var(--spacer-2xs) 16px;
      @include for-desktop {
        padding: 0;
      }
    }
    &__aside,
    &__main {
      display: flex;
      align-items: center;
      padding: 0;
      justify-content: space-between;
    }
    &__aside {
      flex: 0 0 16%;
      padding: var(--spacer-sm) var(--spacer-sm);
      border: 1px solid var(--c-light);
      border-width: 0 1px 0 0;
    }
    &__main {
      flex: 1;
      padding: 0;
      @include for-desktop {
        padding: var(--spacer-xs) var(--spacer-lg);
      }
    }
    &__title {
      --heading-title-font-weight: var(--font-light);
      --heading-title-font-size: var(--font-lg);
    }
    &__filters-button {
      display: flex;
      align-items: center;
      @include for-mobile {
        order: 1;
      }
      svg {
        fill: var(--c-text-muted);
        transition: fill 150ms ease;
      }
      &:hover {
        svg {
          fill: var(--c-primary);
        }
      }
    }
    &__label {
      font-family: var(--font-family-secondary);
      font-weight: var(--font-normal);
      color: var(--c-text-muted);
      margin: 0 var(--spacer-2xs) 0 0;
    }
    &__select {
      --select-padding: 0;
      --select-selected-padding: 0 var(--spacer-base) 0 var(--spacer-2xs);
      --select-margin: 0;
      --chevron-right: 0;

      .sf-select__selected {
        --select-option-font-size: var(--font-sm);
      }

      .sf-select__dropdown {
        min-width: 160px;
      }

      @include for-mobile {
        --select-option-font-size: var(--font-sm);
      }
    }
    &__sort {
      display: flex;
      align-items: center;
      margin: 0;
    }
    &__counter {
      font-family: var(--font-family-secondary);
      margin: auto;
      @include for-desktop {
        margin: auto 0 auto auto;
      }
    }
    &__view {
      display: flex;
      align-items: center;
      @include for-desktop {
        margin: 0 0 0 var(--spacer-2xl);
      }
      @include for-mobile {
        order: -1;
      }
      &-icon {
        cursor: pointer;
      }
      &-label {
        margin: 0 var(--spacer-sm) 0 0;
        font: var(--font-medium) var(--font-xs) / 1.6
          var(--font-family-secondary);
        text-decoration: underline;
      }
    }
  }

  .breadcrumbs {
    padding: var(--spacer-base) var(--spacer-base) var(--spacer-base)
      var(--spacer-sm);
  }

  .category {
    box-sizing: border-box;
    @include for-desktop {
      margin: 0 auto;
    }
  }

  .main {
    &.section {
      flex-direction: column;
      display: flex;
      min-height: 60vh;
      @include for-desktop {
        padding: 0;
        flex-direction: row;
        min-height: 66.6666vh;
      }
    }
  }

  .catalog-tabs {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: 4px;
      background: linear-gradient(
        180deg,
        rgba(9, 30, 66, 0.13) 0,
        rgba(9, 30, 66, 0.13) 1px,
        rgba(9, 30, 66, 0.08) 1px,
        rgba(9, 30, 66, 0) 4px
      );
    }
  }

  .sidebar {
    flex: 0 0 16%;
    padding: var(--spacer-base) var(--spacer-sm);
    border: 1px solid var(--c-light);
    border-width: 0 1px 0 0;
    overflow: hidden;

    .category-list-item {
      cursor: pointer;

      &__name {
        display: flex;
        justify-content: space-between;
        padding: var(--spacer-xs) 0;
        color: var(--sidebar-list-item-header-color);
        transition: color 150ms ease-in-out;
        width: 100%;
        @include border(
          --sidebar-list-item-header-border,
          0 0 1px 0,
          solid,
          var(--c-light)
        );
        font-weight: var(--font-normal);
        font-family: var(--font-family-secondary);
        font-size: var(--h4-font-size);
        line-height: 1.4;
        word-break: break-word;
        &--open {
          --sidebar-list-item-header-border-width: 0;
          --sidebar-list-item-header-color: var(--c-primary);
        }
      }

      &--current {
        text-decoration: underline;

        .category-list-item__name {
          font-weight: 800;
          color: var(--c-primary);
        }
      }
    }
  }
  .list {
    --menu-item-font-size: var(--font-sm);
    &__item {
      &:not(:last-of-type) {
        --list-item-margin: 0 0 var(--spacer-sm) 0;
      }
    }
  }

  .products {
    box-sizing: border-box;
    flex: 1;
    margin: 0;
    padding: 16px;
    margin: -0.625rem;
    &__grid,
    &__list {
      box-sizing: border-box;
      display: flex;
    }
    &__grid {
      flex-direction: row;
      flex-wrap: wrap;

      &-item {
        box-sizing: border-box;
        display: flex;
        flex: 0 1 50%;
        max-width: 50%;

        @include for-tablet {
          flex: 0 0 33.333333333%;
          max-width: 33.333333333%;
        }

        @include for-desktop {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
    }

    &__list {
      flex-direction: column;

      &-item {
        box-sizing: border-box;
        padding: 0.625rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);

        @include for-desktop {
          padding: 1.25rem 0.625rem;
        }
      }
    }
    &__slide-enter {
      opacity: 0;
      transform: scale(0.5);
    }
    &__slide-enter-active {
      transition: all 0.2s ease;
      //transition-delay: calc(0.1s * var(--index));
    }

    @include for-desktop {
      padding: var(--spacer-sm);
      margin: 0;
    }
  }

  .powered-by {
    text-align: center;
    opacity: 0.65;
    font-size: 14px;
    padding: 1.75rem 0px 6rem;

    @include for-desktop {
      padding: 2rem 0px 4rem;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
