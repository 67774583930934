<template>
  <div class="tabs">
    <div class="tabs__header">
      <slot
        v-for="(step, index) in steps"
        name="steps"
        :step-click="stepClick"
        v-bind="{ step }"
      >
        <button
          :key="step.index"
          v-focus
          :class="{
            tabs__step: true,
            'tabs__step--current': index === active,
          }"
          @click="stepClick(index)"
        >
          <span class="tabs__title">{{ step }}</span>
        </button>
      </slot>
    </div>
    <div class="sf-steps__content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import step from "./_internal/step.vue";
import { focus } from "@lib/utility/directives/focus-directive.js";

Vue.component("step", step);
export default {
  name: "tabs",
  directives: {
    focus: focus,
  },
  model: {
    prop: "active",
    event: "change",
  },
  provide() {
    const stepsData = {};
    Object.defineProperty(stepsData, "index", {
      enumerable: false,
      get: () => this.active,
    });
    Object.defineProperty(stepsData, "name", {
      enumerable: false,
      get: () => this.steps[this.active],
    });
    Object.defineProperty(stepsData, "updateSteps", {
      enumerable: false,
      value: this.updateSteps,
    });
    return {
      stepsData,
    };
  },
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      steps: [],
    };
  },
  watch: {
    active: function (val) {
      const tabs = document.querySelector(".tabs .tabs__header");
      const selected = document.querySelector(
        ".tabs__header .tabs__step--current"
      );
      const tabsRect = tabs.getBoundingClientRect();
      const selectedRect = selected.getBoundingClientRect();
      if (tabsRect.right < selectedRect.right) {
        tabs.scrollTo(selectedRect.right - tabsRect.right, 0);
      } else if (tabsRect.left > selectedRect.left) {
        tabs.scrollTo(tabsRect.left - selectedRect.left, 0);
      }
    },
  },
  computed: {
    progress() {
      return {
        "--_steps-progress-width": `${100 / this.steps.length}%`,
        "--_steps-progress-active-step": this.active + 1,
      };
    },
  },
  methods: {
    updateSteps(step) {
      if (this.steps.includes(step)) return;
      this.steps.push(step);
    },
    stepClick(index) {
      this.$emit("change", index);
    },
  },
};
</script>

<style lang="scss">
@import "~@lib/styles/helpers";
.tabs {
  &__header {
    position: relative;
    width: 100%;
    display: flex;
    @include border(--steps-border, 0, solid, var(--c-light));
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__step {
    position: relative;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border: 0;
    background: var(--steps-step-background, transparent);
    padding: var(--steps-step-padding, 0.75rem var(--spacer-xs));
    @include font(
      --step-font,
      var(--font-normal),
      var(--font-sm),
      1.6,
      var(--font-family-secondary)
    );
    color: var(--steps-step-color, var(--c-link));
    cursor: var(--steps-step-cursor, pointer);
    &--current {
      --steps-step-color: var(--c-text);
      --steps-step-cursor: default;
      --step-font-weight: var(--font-semibold);
    }
  }
  &__step--current {
    border-bottom: 3px solid var(--steps-progress-background, var(--c-primary));
  }
  &__progress {
    content: "";
    position: absolute;
    left: 0;
    bottom: var(--steps-progress-bottom, -2px);
    display: block;
    width: var(--steps-progress-width, var(--_steps-progress-width));
    height: var(--steps-progress-height, 3px);
    background: var(--steps-progress-background, var(--c-primary));
    transform: var(
      --steps-progress-transform,
      scale3d(var(--_steps-progress-active-step), 1, 1)
    );
    transform-origin: 0 50%;
    transition: var(--steps-progress-transition, transform 150ms ease-in-out);
  }
  &__title {
    text-align: center;
    white-space: nowrap;
    min-width: 2.5rem;
  }
  &__content {
    padding: var(--steps-content-padding, var(--spacer-base) 0 0 0);
  }
}
</style>
